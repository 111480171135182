
//Smooth scroll on click
$(document).ready(function(){
    // Add smooth scrolling to all links with #
    $("a").on('click', function(event) {
  
      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();
  
        // Store hash
        var hash = this.hash;
  
        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: $(hash).offset().top -75
        }, 800, function(){
     
          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        });
      } // End if
    });

    // PRODUCT PAGE TABS
      $('.tab-container ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
      
      $('.tab-container ul.tabs li a').click(function (g) { 
        var tab = $(this).closest('.tab-container'), 
          index = $(this).closest('li').index();
        
        tab.find('ul.tabs > li').removeClass('current');
        $(this).closest('li').addClass('current');
        
        tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
        tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();
        
        g.preventDefault();
      } );

      var links = document.links;
      for (let i = 0, linksLength = links.length ; i < linksLength ; i++) {
        if (links[i].hostname !== window.location.hostname) {
          links[i].target = '_blank';
          links[i].relList.add('noreferrer', 'noopener');
        }
      }
  });
